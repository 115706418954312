/* Main container */
.MHub-Checkbox {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 1em;
  color: rgba(102, 103, 102, 1);
  margin: 0;
  padding: 0;
  transition: all 0.3s;
  white-space: nowrap;
}

/* disabled */
.MHub-Checkbox.disabled {
  color: rgba(197, 197, 197, 1);
  /* background: rgba(231, 231, 231, 1); */
}
.MHub-Checkbox:not(.disabled),
.MHub-Checkbox > input:not(:disabled) {
  cursor: pointer;
}

/* input */
.MHub-Checkbox > input {
  display: none;
}
.MHub-Checkbox > input + .box {
  position: relative;
  font-size: 1em;
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  border: 1px solid rgba(163, 164, 163, 1);
  background: transparent;
  transition: all 0.25s;
  margin: 0;
  padding: 0;
}

.MHub-Checkbox:not(.basic) > input + .box {
  margin-right: 1rem;
}

.MHub-Checkbox:hover > input + .box {
  background: rgba(255, 255, 255, 0.25);
}
.MHub-Checkbox:not(.special) > input:checked + .box {
  border-color: rgba(44, 173, 195, 1);
  background: rgba(44, 173, 195, 1);
}
.MHub-Checkbox > input + .box:after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 0.25rem;
  height: 0.55rem;
  border: 1px solid white;
  border-width: 0 0.1rem 0.1rem 0;
  transform: rotate(45deg) translate(0.2rem, -0.15rem);
  transform-origin: 50% 50%;
}
.MHub-Checkbox > input:checked + .box:after,
.MHub-Checkbox.special > input:not([type="radio"]):checked + .box:after {
  opacity: 1;
}

/* radio */
.MHub-Checkbox:not(.toggle) > input[type="radio"] + .box {
  border-radius: 50%;
}
.MHub-Checkbox:not(.toggle).checked > input[type="radio"] + .box,
.MHub-Checkbox:not(.toggle) > input[type="radio"]:checked + .box {
  border-color: rgba(44, 173, 195, 1);
  background: transparent;
}
.MHub-Checkbox:not(.toggle) > input[type="radio"] + .box:after {
  top: 50%;
  left: 50%;
  width: 0.57rem;
  height: 0.57rem;
  border-radius: 50%;
  border: none;
  background: rgba(44, 173, 195, 1);
  transform: translate(-50%, -50%);
  transition: all 0.25s;
}

/* toggle */
.MHub-Checkbox.toggle > input + .box {
  width: 2rem;
  border: none;
  border-radius: 1rem;
  background: rgba(208, 209, 208, 1);
}
.MHub-Checkbox.toggle:hover > input + .box {
  background: rgba(204, 205, 204, 1);
}
.MHub-Checkbox.toggle > input + .box:after {
  height: 1rem;
  width: 1rem;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: rgba(163, 164, 163, 1);
  border: none;
  transform: none;
  opacity: 1;
  transition: all 0.25s;
}
.MHub-Checkbox.toggle > input:checked + .box {
  background: rgba(44, 173, 195, 0.5);
}
.MHub-Checkbox.toggle > input:checked + .box:after {
  background: rgba(44, 173, 195, 1);
  left: 1rem;
}

/* special */
.MHub-Checkbox.special > input:not([type="radio"]) + .box {
  border: none;
}
.MHub-Checkbox.special > input:not([type="radio"]) + .box:after {
  content: '';
  position: absolute;
  opacity: 0;
  width: 0.3rem;
  height: 0.6rem;
  border: solid rgba(44, 173, 195, 1);
  border-width: 0 0.08rem 0.08rem 0;
  transform: translate(0.38rem, -0.08rem) rotate(45deg);
  transition: all 0.25s;
}
.MHub-Checkbox.special > input:not([type="radio"]) + .box > .content {
  position: absolute;
  width: 50%;
  height: 50%;
  opacity: 1;
  box-sizing: border-box;
  transition: all 0.25s;
}
.MHub-Checkbox.special > input:not([type="radio"]):checked + .box > .content.top-right {
  opacity: 0;
}
.MHub-Checkbox.special > input:not([type="radio"]) + .box > .content[class*="top-"] {
  top: 0;
  border-top: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Checkbox.special > input:not([type="radio"]) + .box > .content[class*="bottom-"] {
  bottom: 0;
  border-bottom: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Checkbox.special > input:not([type="radio"]) + .box > .content[class*="-left"] {
  left: 0;
  border-left: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Checkbox.special > input:not([type="radio"]) + .box > .content[class*="-right"] {
  right: 0;
  border-right: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Checkbox.special > input:not([type="radio"]):checked + .box > .content {
  border-color: rgba(44, 173, 195, 1);
}
