/* Main container */
.MHub-Label  {
  display: block;
  flex: 1;
  margin: 0.5rem 0;
  padding: 0;
}

/* label */
.MHub-Label > .header {
  margin: 0;
  padding: 0;
  align-items: baseline;
  white-space: nowrap;
  color: rgba(51, 51, 51, 1);
}

/* inverted */
.MHub-Label.inverted > .header {
  color: white;
}

/* error */
.MHub-Label.error > .header,
.MHub-Label.error > .header * {
  color: red;
}

/* focus */
.MHub-Label:hover > .header,
.MHub-Label.focus > .header {
  color: rgba(44, 173, 195, 1);
}

/* children container */
.MHub-Label > .children {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
}

/* inline */
.MHub-Label.inline {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.MHub-Label:not(.inline) > .header + .children {
  margin-top: 0.785rem;
}
.MHub-Label.inline > .children {
  margin-left: 1rem;
  flex: 1 0 auto;
}


/* vertical */
.MHub-Label.vertical > .children {
  flex-direction: column;
}
.MHub-Label.vertical > .children > * {
  flex: 1 0 auto;
  margin-left: 0;
  margin-right: 0;
}
.MHub-Label.vertical > .children > :not(:last-child) {
  margin-bottom: 0.5rem;
}
/* children not attached */
.MHub-Label:not(.vertical) > .children > .MHub-Helper-Wrapper:not(:first-child) > *:not(.MHub-Helper):not([class*="attached"]):not(.attached-right) {
  margin-left: 0.5rem;
}
.MHub-Label:not(.vertical) > .children > .MHub-Helper-Wrapper:not(:last-child) > *:not(.MHub-Helper):not([class*="attached"]):not(.attached-left) {
  margin-right: 0.5rem;
}
