/* select base */
.MHub-Select-Base {
  flex: 1;
  min-width: 5rem;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* hide arrow in IE */
select.MHub-Select-Base::-ms-expand {
  display: none;
}
