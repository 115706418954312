/* main container */
.MHub-Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  pointer-events: none;
  background: transparent;
  transition: all 0.25s ease-out;
}

/* visible */
.MHub-Modal.visible {
  user-select: text;
  pointer-events: visible;
  background: rgba(0, 0, 0, 0.5);
}

/* dim overlay */
.MHub-Modal.dimOverlay.dimOverlayClickable {
  cursor: pointer;
}

@keyframes modalEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* header */
.MHub-Modal > .MHub-Modal-Content .header {
  margin-bottom: 1rem;
}

/* content */
.MHub-Modal > .MHub-Modal-Content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  cursor: default !important;
  animation: modalEnter 0.25s ease-out; /* NOTE: must match transition duration */
}

/* close button */
.MHub-Modal > .MHub-Modal-Content .close {
  height: 1.1em;
  padding: 0;
  font-size: 1em;
}

/* fixed scroll */
.MHub-fixed-scroll {
  overflow: hidden;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 992px) {
  .MHub-fixed-scroll {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .MHub-Modal > .MHub-Modal-Content {
    width: 100% !important;
  }
}

.MHub-Modal.small > .MHub-Modal-Content {
  width: 27rem;
}
.MHub-Modal.medium > .MHub-Modal-Content {
  width: 41rem;
}
.MHub-Modal.large > .MHub-Modal-Content {
  width: 55rem;
}
