.MHub-Dropdown {
  display: inline-block;
  position: relative;
  padding-right: 1.5rem;
  overflow: visible;
}

.MHub-Dropdown.multiple span:not(:first-of-type) {
  padding-left: 1rem;
}

.MHub-Dropdown-Placeholder {
  padding-right: 0.75rem;
  min-width: 7rem;
}

.MHub-Dropdown:not(.multiple) .MHub-Dropdown-Placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.MHub-Dropdown.multiple > .MHub-Dropdown-Placeholder {
  display: inline-table;
}

.MHub-Dropdown.fluid > .MHub-Dropdown-Placeholder {
  width: 100%;
}

/* arrow */
.MHub-Dropdown-Arrow {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  margin: auto 0;
  width: 0;
  height: 0;
  border-left: 0.285rem solid transparent;
  border-right: 0.285rem solid transparent;
  border-top: 0.285rem solid currentColor;
  transition: all 0.25s;
}
.MHub-Dropdown-Arrow.active {
  border-top-color: rgba(44, 173, 195, 1);
}
.MHub-Dropdown-Arrow.disabled {
  border-top-color: rgba(197, 197, 197, 1);
}

.MHub-Dropdown.disabled > .MHub-Dropdown-Placeholder {
  color: rgba(197, 197, 197, 1);
}

/* list container */
.MHub-Dropdown-List {
  position: absolute;
  height: auto;
  width: auto;
  background: #F5F5F5;
  box-shadow: 0 0 3px rgba(102, 103, 102, 0.75);
  z-index: 5;
}

/* list container withPortal */
.MHub-Dropdown-List:not(.withPortal) {
  min-width: 100%;
}

.MHub-Dropdown-List.withPortal {
  color: rgba(102, 103, 102, 1);
  /* z-index set to 1000 to match modal's z index */
  z-index: 1000;
  min-width: 3rem;
}

/* direction */
.MHub-Dropdown-List.direction-bottom:not(.direction-left):not(.direction-right) {
  left: 0;
  top: calc(100% + 1px);
}

.MHub-Dropdown-List.direction-top:not(.direction-left):not(.direction-right) {
  left: 0;
  bottom: 100%;
}

/* left */
.MHub-Dropdown-List.direction-left {
  right: 100%;
}

.MHub-Dropdown-List.left.direction-bottom {
  top: 0;
}

.MHub-Dropdown-List.left.direction-top {
  bottom: 0;
}

/* right */
.MHub-Dropdown-List.direction-right {
  left: 100%;
}

.MHub-Dropdown-List.direction-right.direction-bottom {
  top: 0;
}

.MHub-Dropdown-List.direction-right.direction-top {
  bottom: 0;
}

/* list scrollable */
.MHub-Dropdown-List.scrollable {
  overflow: auto;
  max-height: 10rem;
}
/* bordered list */
*.bordered .MHub-Dropdown-List {
  background: white;
  border: 1px solid rgba(163, 164, 163, 0.5);
}
*.bordered.rounded .MHub-Dropdown-List {
  border-radius: 0.285rem;
}
/* attached list */
.MHub-Dropdown-List.attached {
  border-radius: 0 0 0.285rem 0.285rem !important;
}
.MHub-Dropdown-List > .MHub-Dropdown-Item {
  z-index: 1;
  border:none;
  display: flex;
  padding: 0.3rem 0.75rem;
  width: 100%;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  color: inherit;
  background: inherit;
}
.MHub-Dropdown-List > .MHub-Dropdown-Item.text-align-left {
  justify-content: flex-start;
}
.MHub-Dropdown-List > .MHub-Dropdown-Item.text-align-center {
  justify-content: center;
}
.MHub-Dropdown-List > .MHub-Dropdown-Item.text-align-right {
  justify-content: flex-end;
}
.MHub-Dropdown-List > .MHub-Dropdown-Item.selected {
  background: rgba(222, 242, 245, 1);
}
.MHub-Dropdown-List.disabled,
.MHub-Dropdown-List > .MHub-Dropdown-Item.disabled {
  background: rgba(231, 231, 231, 1);
  color: rgba(197, 197, 197, 1);
  cursor: initial;
}
.MHub-Dropdown-List > .MHub-Dropdown-Item:hover:not(.disabled) {
  background: #E8F2FA;
}
.MHub-Dropdown-List > .MHub-Dropdown-Item:last-of-type {
  border-radius: 0 0 0.285rem 0.285rem;
}

/* Tag */
.MHub-Dropdown-Tag > * {
  font-size: 0.8em;
}

.MHub-Dropdown-Tag {
  display: inline-flex;
  word-break: break-word;
  flex-direction: row;
  align-items: center;
  border: 1px solid currentColor;
  border-radius: 1rem;
  color: grey;
  border-color: grey;
  text-align: center;
  padding: 0.357rem 0.857rem;
  margin: 0.25em;
}

.MHub-Dropdown-Tag button {
  padding: 0;
  margin-left: 0.625rem;
}

/* ===== color ==== */
/* blue */
.MHub-Dropdown-Tag.blue {
  border-color: rgba(44, 173, 195, 1);
  color: rgba(44, 173, 195, 1);
}
.MHub-Dropdown-Tag.blue:hover:not(.disabled):not(:disabled),
.MHub-Dropdown-Tag.blue:active:not(.disabled):not(:disabled) {
  border-color: rgba(60, 198, 221, 1);
  color: rgba(60, 198, 221, 1);
}

/* red */
.MHub-Dropdown-Tag.red {
  border-color: red;
  color: red;
}
.MHub-Dropdown-Tag.red:hover:not(.disabled):not(:disabled),
.MHub-Dropdown-Tag.red:active:not(.disabled):not(:disabled) {
  border-color: darkred;
  color: darkred;
}

/* disabled */
.MHub-Dropdown-Tag.disabled {
  border-color: rgba(197, 197, 197, 1);
  color: rgba(197, 197, 197, 1);
}
