.MHub-Iframe {
  position: relative;
  display: flex;
  min-width: 5rem;
  min-height: 5rem;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: all 0.25s;
}

.MHub-Iframe .placeholder {
  position: absolute;
  color: rgba(163, 164, 163, 1);
}

.MHub-Iframe:not(.loaded) {
  background: rgba(163, 164, 163, 0.25);
}

.MHub-Iframe .ignore-click {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  left: 0;
  top: 0;
}

.MHub-Iframe.readOnly iframe {
  pointer-events: none;
  user-select: none;
}
