
/* list */
.MHub-Sortable-List {
}

/* item */
.MHub-Sortable-Item {
}

/* handle */
.MHub-Sortable-Handle {
  color: rgba(44, 173, 195, 1);
}
.MHub-Sortable-Handle:hover:not(.disabled) {
  color: rgba(60, 198, 221, 1)
}
.MHub-Sortable-Handle:not(.disabled) {
  cursor: pointer;
}

/* dragging helper */
.MHub-Sortable-Helper {
  z-index: 1001;
  background-color: #fff;
  box-shadow: 0 0 0.357rem 0 rgba(0, 0, 0, .1);
}
.MHub-Sortable-Helper.axis-x {
  box-shadow: 0.357rem 0 0.357rem -0.357rem rgba(0, 0, 0, .1),
    -0.357rem 0 0.357rem -0.357rem rgba(0, 0, 0, .1);
}
.MHub-Sortable-Helper.axis-y {
  box-shadow: 0 0.357rem 0.357rem -0.357rem rgba(0, 0, 0, .1),
      0 -0.357rem 0.357rem -0.357rem rgba(0, 0, 0, .1);
}
