/* headers */
.MHub-Markdown h1:first-child,
.MHub-Markdown h2:first-child,
.MHub-Markdown h3:first-child,
.MHub-Markdown h4:first-child,
.MHub-Markdown h5:first-child {
  margin-top: 0;
}

.MHub-Markdown h1,
.MHub-Markdown h2,
.MHub-Markdown h3,
.MHub-Markdown h4,
.MHub-Markdown h5 {
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 16px;
  margin-top: 24px;
}

/* a */
.MHub-Markdown a {
  color: rgba(44, 173, 195, 1);
  text-decoration: none;
}

.MHub-Markdown a:hover {
  text-decoration: underline;
}

/* blockquote */
.MHub-Markdown blockquote {
  padding: 10px 20px;
  border-left: 5px solid #eee;
  background-color: #f5f5f5;
}

/* code */
.MHub-Markdown *:not(pre) > code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

.MHub-Markdown li+li {
  margin-top: 0.25em;
}
