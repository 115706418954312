/* main container */
.MHub-Helper-Wrapper {
  display: flex;
  flex-direction: column;
}

/* base style */
.MHub-Helper {
  width: 100%;
  min-height: 0.8rem;
  line-height: 0.8em;
  font-size: 0.8em;
  margin-top: 0.3rem;
  font-weight: 300;
  /* TODO: possibly need white space none and wrap content inside */
}

/* color */
.MHub-Helper.error {
  color: red;
}
