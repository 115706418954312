/* Main container */
.MHub-Container {
  display: block;
  margin: 0;
  padding: 0;
  background: transparent;
  box-sizing: border-box;
}

/* padded */
.MHub-Container.padded:not(.basic) {
  padding: 1.5rem;
}

/* padded */
.MHub-Container:not(.basic) {
  background: white;
  box-shadow: 0.214rem 0.214rem 0.357rem 0 rgba(0, 0, 0, 0.1);
}

/* disabled */
.MHub-Container.disabled {
  color: rgba(197, 197, 197, 1);
  background: rgba(238, 238, 238, 1);
}

/* clipped */
.MHub-Container.clipped:not(.scrollable):not(.noContent) {
  overflow: hidden;
}

/* content */
.MHub-Container > .content {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
}

/* scrollable */
.MHub-Container.scrollable.noContent,
.MHub-Container.scrollable > .content {
  overflow: auto;
}
