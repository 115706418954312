.MHub-Default html,
.MHub-Default address,
.MHub-Default blockquote,
.MHub-Default body,
.MHub-Default dd,
.MHub-Default div,
.MHub-Default dl,
.MHub-Default dt,
.MHub-Default fieldset,
.MHub-Default form,
.MHub-Default frame,
.MHub-Default frameset,
.MHub-Default h1,
.MHub-Default h2,
.MHub-Default h3,
.MHub-Default h4,
.MHub-Default h5,
.MHub-Default h6,
.MHub-Default noframes,
.MHub-Default ol,
.MHub-Default p,
.MHub-Default ul,
.MHub-Default center,
.MHub-Default dir,
.MHub-Default hr,
.MHub-Default menu,
.MHub-Default pre {
  display: block;
  unicode-bidi: embed;
}

.MHub-Default li {
  display: list-item;
}

.MHub-Default head {
  display: none;
}

.MHub-Default table {
  display: table;
}

.MHub-Default tr {
  display: table-row;
}

.MHub-Default thead {
  display: table-header-group;
}

.MHub-Default tbody {
  display: table-row-group;
}

.MHub-Default tfoot {
  display: table-footer-group;
}

.MHub-Default col {
  display: table-column;
}

.MHub-Default colgroup {
  display: table-column-group;
}

.MHub-Default td, .MHub-Default th {
  display: table-cell;
}

.MHub-Default caption {
  display: table-caption;
}

.MHub-Default th {
  font-weight: bolder;
  text-align: center;
}

.MHub-Default caption {
  text-align: center;
}

.MHub-Default body {
  margin: 8px;
}

.MHub-Default h1 {
  font-size: 2em;
  margin: .67em 0;
}

.MHub-Default h2 {
  font-size: 1.5em;
  margin: .75em 0;
}

.MHub-Default h3 {
  font-size: 1.17em;
  margin: .83em 0;
}

.MHub-Default h4, .MHub-Default p, .MHub-Default blockquote, .MHub-Default ul, .MHub-Default fieldset, .MHub-Default form, .MHub-Default ol, .MHub-Default dl, .MHub-Default dir, .MHub-Default menu {
  margin: 1.12em 0;
}

.MHub-Default h5 {
  font-size: .83em;
  margin: 1.5em 0;
}

.MHub-Default h6 {
  font-size: .75em;
  margin: 1.67em 0;
}

.MHub-Default h1, .MHub-Default h2, .MHub-Default h3, .MHub-Default h4, .MHub-Default h5, .MHub-Default h6, .MHub-Default b, .MHub-Default strong {
  font-weight: bolder;
}

.MHub-Default blockquote {
  margin-left: 40px;
  margin-right: 40px;
}

.MHub-Default i, .MHub-Default cite, .MHub-Default em, .MHub-Default var, .MHub-Default address {
  font-style: italic;
}

.MHub-Default pre, .MHub-Default tt, .MHub-Default code, .MHub-Default kbd, .MHub-Default samp {
  font-family: monospace;
}

.MHub-Default pre {
  white-space: pre;
}

.MHub-Default button, .MHub-Default textarea, .MHub-Default input, .MHub-Default select {
  display: inline-block;
}

.MHub-Default big {
  font-size: 1.17em;
}

.MHub-Default small, .MHub-Default sub, .MHub-Default sup {
  font-size: .83em;
}

.MHub-Default sub {
  vertical-align: sub;
}

.MHub-Default sup {
  vertical-align: super;
}

.MHub-Default table {
  border-spacing: 2px;
}

.MHub-Default thead, .MHub-Default tbody, .MHub-Default tfoot {
  vertical-align: middle;
}

.MHub-Default td, .MHub-Default th, .MHub-Default tr {
  vertical-align: inherit;
}

.MHub-Default s, .MHub-Default strike, .MHub-Default del {
  text-decoration: line-through;
}

.MHub-Default hr {
  border: 1px inset;
}

.MHub-Default ol, .MHub-Default ul, .MHub-Default dir, .MHub-Default menu, .MHub-Default dd {
  margin-left: 40px;
}

.MHub-Default ol {
  list-style-type: decimal;
}

.MHub-Default ol ul, .MHub-Default ul .MHub-Default ol, .MHub-Default ul .MHub-Default ul, .MHub-Default ol .MHub-Default ol {
  margin-top: 0;
  margin-bottom: 0;
}

.MHub-Default u, .MHub-Default ins {
  text-decoration: underline;
}

.MHub-Default br:before {
  content: "\A";
  white-space: pre-line;
}

.MHub-Default center {
  text-align: center;
}

.MHub-Default :focus {
  outline: thin dotted invert;
}

.MHub-Markdown ol {
  list-style-type: decimal;
}

.MHub-Default ul {
  list-style-type: disc;
}
