.MHub-Row {
  flex: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.MHub-Row.around {
  justify-content: space-around;
}
.MHub-Row.between {
  justify-content: space-between;
}
.MHub-Row.start {
  justify-content: flex-start;
}
.MHub-Row.center {
  justify-content: center;
}
.MHub-Row.end {
  justify-content: flex-end;
}
.MHub-Row.top {
  align-items: flex-start;
}
.MHub-Row.middle {
  align-items: center;
}
.MHub-Row.bottom {
  align-items: flex-end;
}

@media (min-width: 1200px) {
  .MHub-Row.around-xl {
    justify-content: space-around;
  }
  .MHub-Row.between-xl {
    justify-content: space-between;
  }
  .MHub-Row.start-xl {
    justify-content: flex-start;
  }
  .MHub-Row.center-xl {
    justify-content: center;
  }
  .MHub-Row.end-xl {
    justify-content: flex-end;
  }
  .MHub-Row.top-xl {
    align-items: flex-start;
  }
  .MHub-Row.middle-xl {
    align-items: center;
  }
  .MHub-Row.bottom-xl {
    align-items: flex-end;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .MHub-Row.around-lg {
    justify-content: space-around;
  }
  .MHub-Row.between-lg {
    justify-content: space-between;
  }
  .MHub-Row.start-lg {
    justify-content: flex-start;
  }
  .MHub-Row.center-lg {
    justify-content: center;
  }
  .MHub-Row.end-lg {
    justify-content: flex-end;
  }
  .MHub-Row.top-lg {
    align-items: flex-start;
  }
  .MHub-Row.middle-lg {
    align-items: center;
  }
  .MHub-Row.bottom-lg {
    align-items: flex-end;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .MHub-Row.around-md {
    justify-content: space-around;
  }
  .MHub-Row.between-md {
    justify-content: space-between;
  }
  .MHub-Row.start-md {
    justify-content: flex-start;
  }
  .MHub-Row.center-md {
    justify-content: center;
  }
  .MHub-Row.end-md {
    justify-content: flex-end;
  }
  .MHub-Row.top-md {
    align-items: flex-start;
  }
  .MHub-Row.middle-md {
    align-items: center;
  }
  .MHub-Row.bottom-md {
    align-items: flex-end;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .MHub-Row.around-sm {
    justify-content: space-around;
  }
  .MHub-Row.between-sm {
    justify-content: space-between;
  }
  .MHub-Row.start-sm {
    justify-content: flex-start;
  }
  .MHub-Row.center-sm {
    justify-content: center;
  }
  .MHub-Row.end-sm {
    justify-content: flex-end;
  }
  .MHub-Row.top-sm {
    align-items: flex-start;
  }
  .MHub-Row.middle-sm {
    align-items: center;
  }
  .MHub-Row.bottom-sm {
    align-items: flex-end;
  }
}

@media (max-width: 575px) {
  .MHub-Row.around-xs {
    justify-content: space-around;
  }
  .MHub-Row.between-xs {
    justify-content: space-between;
  }
  .MHub-Row.start-xs {
    justify-content: flex-start;
  }
  .MHub-Row.center-xs {
    justify-content: center;
  }
  .MHub-Row.end-xs {
    justify-content: flex-end;
  }
  .MHub-Row.top-xs {
    align-items: flex-start;
  }
  .MHub-Row.middle-xs {
    align-items: center;
  }
  .MHub-Row.bottom-xs {
    align-items: flex-end;
  }
}
