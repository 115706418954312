.MHub-AppHeader {
  position: relative;
  padding: 2rem;
  transition: all 0.25s;
}

/* inverted */
.MHub-AppHeader.inverted {
  background-color: rgba(0, 0, 0, 1);
}
