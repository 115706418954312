.MHub-Datasheet {
  border: 1px solid #bbbbbb;
  width: 100%;
  background-color: white;
}

/* disable border */
.MHub-Datasheet .data-editor {
  border: none;
}

/* error selected / error editing */
.data-grid-container .data-grid .cell.error.selected,
.data-grid-container .data-grid .cell.error.editing {
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(253, 203, 203, 0.5);
  border: none;
  border-right: 1px double red;
  border-bottom: 1px double red;
}

/* error overlaps border left and border top */
.data-grid-container .data-grid .cell.error.selected::before {
  content: '';
  position: absolute;
  pointer-events: none;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px double red;
  border-top: 1px double red;
}

/* error update */
.data-grid-container .data-grid .cell.error.updated {
  background-color: rgba(253, 203, 203, 0.7);
  transition : background-color 0ms ease ;
}


/* error */
.MHub-Datasheet .cell.error {
  box-shadow: inset 0 -100px 0 rgba(253, 203, 203, 0.5);
}

/* styled */
.MHub-Datasheet.styled,
.MHub-Datasheet.styled .cell,
.MHub-Datasheet.styled .cell.error::before {
  border: none !important;
  background: none;
  color: #666766 !important;
}

/* header row */
.MHub-Datasheet.styled tr:first-child .cell {
  font-weight: bold;
  border-bottom: solid 1px #A3A4A3 !important;
}

/* dropdown inside datasheet */
.MHub-Datasheet .MHub-Dropdown {
  padding: 0;
  background: none;
  border: none;
}

.MHub-Datasheet .MHub-InputWrapper:hover {
  background: none;
}

.MHub-Datasheet .MHub-Dropdown .MHub-Dropdown-Placeholder {
  padding: 0;
}

/* default styles from package */
span.data-grid-container, span.data-grid-container:focus {
  outline: none;
}

.data-grid-container .data-grid {
  table-layout: fixed;
  border-collapse: collapse;
}

.data-grid-container .data-grid .cell.updated {
    background-color: rgba(0, 145, 253, 0.16);
    transition : background-color 0ms ease ;
}

.data-grid-container .data-grid .cell {
  /* relative for pseudo before element */
  position: relative;
  height: 17px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition : background-color 500ms ease;
  vertical-align: middle;
  border: 1px solid #DDD;
  /* padding: 0.5rem; */
}

.data-grid-container .data-grid .cell.selected {
  border: 1px double rgb(33, 133, 208);
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
}

.data-grid-container .data-grid .cell.read-only {
  background: whitesmoke;
  color: #999;
  text-align: center;
}

.data-grid-container .data-grid .cell > .text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  padding: 2px;
  text-align: left;
  width: calc(100% - 6px);
  height: 11px;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell {
  vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
  white-space: nowrap;
  overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
  white-space: nowrap;
  overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
  display: block;
  /* ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
