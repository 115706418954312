/* Main container */
.MHub-InputWrapper {
  position: relative;
  border: none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: rgba(102, 103, 102, 1);
  border-bottom: 1px solid currentColor;
  box-sizing: border-box;
  background: rgba(249, 250, 249, 0.75);
  align-items: center;
  margin: 0;
  padding: 0.428rem 1rem;
  transition: all 0.25s;
}

.MHub-InputWrapper:not(.topNode):not(.bottomNode) {
  display: inline-flex;
}

.MHub-InputWrapper.editableOnClick:not(.focus),
.MHub-InputWrapper.editableOnClick.readOnly {
  border: none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  overflow: hidden;
  background: none;
  display: inline-flex;
  align-items: center;
  padding: 0;
}

/* rounded */
.MHub-InputWrapper.rounded {
  border-radius: 0.285rem 0.285rem 0 0;
}

/* bordered */
.MHub-InputWrapper.bordered {
  background: white;
  border: 1px solid rgba(163, 164, 163, 0.5);
  padding: 0.92rem 1.14rem;
}
.MHub-InputWrapper.bordered.rounded {
  border-radius: 0.285rem;
}

/* inverted */
.MHub-InputWrapper.inverted {
  color: white;
  border-color: currentColor;
  background: rgba(255, 255, 255, 0.1);
}

/* error */
.MHub-InputWrapper.error {
  background: rgba(221, 135, 135, 0.15);
  border-color: red;
}

/* base */
.MHub-InputWrapper > * {
  border: none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 1em;
  color: inherit;
  background: transparent;
  font-family: inherit;
  box-sizing: inherit;
  text-align: inherit;
}

/* focus */
.MHub-InputWrapper:hover:not(.disabled):not(.readOnly),
.MHub-InputWrapper.focus {
  /* NOTE: fix for IE */
  background: rgba(222, 242, 245, 1);
  border-color: rgba(44, 173, 195, 1);
}
.MHub-InputWrapper.inverted:hover:not(.disabled):not(.readOnly),
.MHub-InputWrapper.inverted.focus {
  /* NOTE: fix for IE */
  background: rgba(255, 255, 255, 0.25);
  border-color: currentColor;
}

/* disabled */
.MHub-InputWrapper.disabled {
  color: rgba(197, 197, 197, 0.5);
  border-color: currentColor;
  background: rgba(231, 231, 231, 1);
  cursor: initial;
}

/* z-index */
.MHub-InputWrapper.error,
.MHub-InputWrapper:hover:not(.disabled),
.MHub-InputWrapper.focus {
  z-index: 1;
}

.MHub-InputWrapper.error.inverted {
  background: rgba(255, 0, 0, 0.25);
}
