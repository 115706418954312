.MHub-Textarea-Base {
  flex: 1;
  min-width: 5rem;
  width: 100%;
  margin: 0;
  padding: 0;
}

.MHub-Textarea .copy-button {
  margin-left: 1rem;
}

.MHub-Textarea-Base:not(.resizable) {
  resize: none;
}

.MHub-Textarea-Base.resizable-vertical {
  resize: vertical;
}

.MHub-Textarea-Base.resizable-horizontal {
  resize: horizontal;
}
