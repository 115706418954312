.MHub-Tooltip {
  padding: 0.428rem 1rem;
  background: white;
  border: 1px solid rgba(163, 164, 163, 0.5);
  box-shadow: 0.214rem 0.214rem 0.357rem 0 rgba(0, 0, 0, 0.1);
}

/* rounded */
.MHub-Tooltip.rounded {
  border-radius: 0.285rem;
}

/* triangle */
.MHub-Tooltip:after,
.MHub-Tooltip:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  transform-origin: 50% 50%;
  background: transparent;
  transition: all 0.25s;
}

/* top triangle background */
.MHub-Tooltip.direction-top:after {
  top: 100%;
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-top: 0.75rem solid white;
}
/* top triangle border */
.MHub-Tooltip.direction-top:before {
  top: calc(100% + 1px);
  border-left: calc(0.375rem + 1px) solid transparent;
  border-right: calc(0.375rem + 1px) solid transparent;
  border-top: calc(0.75rem + 1px) solid;
  border-top-color: inherit;
}

/* bottom triangle background */
.MHub-Tooltip.direction-bottom:after {
  bottom: 100%;
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-bottom: 0.75rem solid white;
}
/* bottom triangle border */
.MHub-Tooltip.direction-bottom:before {
  bottom: calc(100% + 1px);
  border-left: calc(0.375rem + 1px) solid transparent;
  border-right: calc(0.375rem + 1px) solid transparent;
  border-bottom: calc(0.75rem + 1px) solid;
  border-bottom-color: inherit;
}

/* left triangle background */
.MHub-Tooltip.direction-left:after {
  left: 100%;
  border-top: 0.375rem solid transparent;
  border-bottom: 0.375rem solid transparent;
  border-left: 0.75rem solid white;
}
/* left triangle border */
.MHub-Tooltip.direction-left:before {
  left: calc(100% + 1px);
  border-top: calc(0.375rem + 1px) solid transparent;
  border-bottom: calc(0.375rem + 1px) solid transparent;
  border-left: calc(0.75rem + 1px) solid;
  border-left-color: inherit;
}

/* right triangle background */
.MHub-Tooltip.direction-right:after {
  right: 100%;
  border-top: 0.375rem solid transparent;
  border-bottom: 0.375rem solid transparent;
  border-right: 0.75rem solid white;
}
/* right triangle border */
.MHub-Tooltip.direction-right:before {
  right: calc(100% + 1px);
  border-top: calc(0.375rem + 1px) solid transparent;
  border-bottom: calc(0.375rem + 1px) solid transparent;
  border-right: calc(0.75rem + 1px) solid;
  border-right-color: inherit;
}

/* extra setup for horizontal direction */
.MHub-Tooltip[class*="direction-"]:not(.direction-top):not(.direction-bottom):after {
  top: calc(50% + 1px);
  transform: translateY(calc(-50% - 1px));
}
.MHub-Tooltip[class*="direction-"]:not(.direction-top):not(.direction-bottom):before {
  top: 50%;
  transform: translateY(-50%);
}

/* extra setup for vertical direction */
.MHub-Tooltip[class*="direction-"]:not(.direction-left):not(.direction-right):after {
  right: calc(50% + 1px);
  transform: translateX(calc(50% + 1px));
}
.MHub-Tooltip[class*="direction-"]:not(.direction-left):not(.direction-right):before {
  right: 50%;
  transform: translateX(50%);
}
