/* main container */
.MHub-Popup {
  position: relative;
  display: inline-block;
}

/* content */
.MHub-Popup-Content {
  position: absolute;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  box-sizing: border-box;
  transition: opacity 0.25s ease-out;
  transform-origin: 50% 50%;
  z-index: 50;
}

/* rounded */
.MHub-Popup-Content.rounded {
  border-radius: 0.285rem;
}

/* visible */
.MHub-Popup.hoverable:hover > .MHub-Popup-Content,
.MHub-Popup-Content.visible {
  opacity: 1;
  user-select: text;
  pointer-events: auto;
}

/* top */
.MHub-Popup-Content.direction-top {
  bottom: 100%;
}
/* bottom */
.MHub-Popup-Content.direction-bottom {
  top: 100%;
}

/* left */
.MHub-Popup-Content.direction-left {
  right: 100%;
}
/* right */
.MHub-Popup-Content.direction-right {
  left: 100%;
}

/* extra setup for horizontal direction */
.MHub-Popup-Content[class*="direction-"]:not(.direction-top):not(.direction-bottom) {
  bottom: 50%;
  transform: translateY(50%);
}
/* extra setup for vertical direction */
.MHub-Popup-Content[class*="direction-"]:not(.direction-left):not(.direction-right) {
  left: 50%;
  transform: translateX(-50%);
}
