/* main container */
.MHub-SideBar {
  position: fixed;
  top: 0;
  right: 100%;
  width: 15rem;
  height: 100%;
  padding: 0;
  color: white;
  background: rgba(119, 143, 155, 1);
  overflow-x: hidden;
  box-sizing: border-box;
  z-index: 100;
  transition: all 0.25s;
}

/* side bar button container */
.MHub-SideBar-Button-Container {
  transition: margin-left 0.25s;
  position: fixed;
  height: 100%;
  width: 2rem;
  z-index: 400;
  top: 0;
  left: 0;
}

.MHub-SideBar-Button-Container > .sidebar-button{
  position: absolute;
  margin-top: 3rem;
  min-width: 2rem;
  min-height: 2rem;
  padding: 0.25rem;
  left: -1rem;
}

.MHub-SideBar-Button-Container > .sidebar-scrollbar{
  z-index: 1;
}

/* side bar content */
@keyframes slide_left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.MHub-SideBar > .MHub-SideBar-Content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem 0;
  transform: translateX(-100%);
  transition: all 0.25s ease-in-out;
}
.MHub-SideBar > .MHub-SideBar-Content:last-child {
  transform: translateX(0);
}
.MHub-SideBar > .MHub-SideBar-Content:last-child:not(:first-child) {
  animation: slide_left 0.25s ease-in-out;
}

/* main container */
.MHub-SideBar + .MHub-SideBar-Container {
  transition: all 0.25s;
}

/* visible */
.MHub-SideBar.visible {
  right: calc(100% - 15rem);
}

.MHub-SideBar:not(.visible) {
  right: calc(100% - 1rem);
}

.MHub-SideBar-Button-Container.visible {
  margin-left: 15rem;
}

.MHub-SideBar-Button-Container:not(.visible) {
  margin-left: 0.8rem;
}

body.MHub-SideBar-Visible .MHub-SideBar-Container {
  margin-left: 15rem; /* Same as the width of the sidenav */
  flex: 1;
  width: 1px;
}

/* menu hover */
.MHub-SideBar .MHub-Menu.inverted > .MHub-Menu-Item:not(.header):not(.disabled):not(:disabled):hover,
.MHub-SideBar .MHub-Menu.inverted > .MHub-Menu-Item:not(.header).active {
  background: rgba(82, 106, 117, 1);
}
