/* primary container */
.MHub-AppNav-Primary {
  position: fixed;
  height: 100%;
  width: 4rem;
  background: rgba(6, 54, 60, 1);
  /* for popup */
  z-index: 990;
}

/* secondary container */
.MHub-AppNav-Secondary {
  position: fixed;
  height: 100%;
  width: 18rem;
  left: 3.75rem;
  overflow: auto;
  overflow-x: hidden;
  transition: all 0.3s;
  z-index: 980;
}

/* button */
.MHub-AppNav-Button {
  background: white !important;
  position: fixed;
  left: calc(21.75rem - 1.75rem / 2);
  width: 1.75rem;
  height: 1.75rem;
  margin-top: 1.5rem;
  z-index: 980;
}

/* content container */
.MHub-AppNav-Content {
  transition: all 0.3s;
}

/* button */
.MHub-AppNav-Button > i {
  font-weight: bold;
}

/* visible */
.MHub-AppNav-Button.visible,
.MHub-AppNav-Secondary.visible {
  margin-left: 0;
}

.MHub-AppNav-Button:not(.visible),
.MHub-AppNav-Secondary:not(.visible) {
  margin-left: -16.5rem;
  /* disable scroll */
  overflow: hidden;
}

body.MHub-AppNav-Visible .MHub-AppNav-Content,
.MHub-AppNav-Content.visible {
  margin-left: 22rem;
}

.MHub-AppNav-Content:not(.visible) {
  margin-left: 5.25rem;
}

/* primary props */
/* popup */
.MHub-AppNav-Primary .MHub-Menu .MHub-Popup,
.MHub-AppNav-Primary .MHub-Menu .MHub-Popup > .MHub-Menu-Item {
  transition: all 0.3s;
}

.MHub-AppNav-Primary .MHub-Menu .MHub-Menu-Item {
  color: white;
  padding: 1.25rem;
}

/* hover / active */
.MHub-AppNav-Primary .MHub-Menu .MHub-Menu-Item:not(.header):not(.disabled):hover,
.MHub-AppNav-Primary .MHub-Menu .MHub-Menu-Item:not(.header):not(.disabled).active,
.MHub-AppNav-Primary .MHub-Menu .MHub-Popup > .MHub-Menu-Item:not(.header):not(.disabled).active {
  background: rgba(65, 155, 125, 1);
}

/* secondary props */
.MHub-AppNav-Secondary .MHub-Menu .MHub-Menu-Item {
  white-space: normal;
  overflow-wrap: break-word;
}

.MHub-AppNav-Secondary .MHub-Menu .MHub-Menu-Item.header {
  font-size: 1em;
}

/* hover / active */
.MHub-AppNav-Secondary .MHub-Menu .MHub-Menu-Item:not(.header):not(.disabled):hover,
.MHub-AppNav-Secondary .MHub-Menu .MHub-Menu-Item:not(.header):not(.disabled).active {
  background: rgba(236,247,238, 1);
  color: rgba(102, 103, 102, 1) !important;
  border-radius: 0.25rem;
}

