/* main container */
.MHub-Menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

/* item */
.MHub-Menu > .MHub-Menu-Item {
  color: rgba(102, 103, 102, 1);
  padding: 0.35rem 1.07rem;
  background: transparent;
  transition: all 0.3s;
  white-space: nowrap;
  font-size: 1em;
}
.MHub-Menu > .MHub-Menu-Item.header {
  font-size: 1.1em;
  font-weight: bold;
  padding: 0.35rem 1rem;
}
.MHub-Menu:not(.tab) > .MHub-Menu-Item.header:not(:first-child) {
  margin-top: 2rem;
}

/* full */
.MHub-Menu.outline.horizontal:not(.full) > .MHub-Menu-Item {
  min-width: 9.5rem;
}

/* scrollable */
.MHub-Menu.scrollable {
  overflow: auto;
}

/* outline */
.MHub-Menu.outline {
  background: transparent;
}
.MHub-Menu.outline > .MHub-Menu-Item {
  background: transparent;
  position: relative;
  margin: 0.5rem 0;
  padding: 1rem;
}
.MHub-Menu.outline > .MHub-Menu-Item:first-child {
  margin-top: 0;
}
.MHub-Menu.outline > .MHub-Menu-Item:last-child {
  margin-bottom: 0;
}
.MHub-Menu.outline > .MHub-Menu-Item:not(.header):after {
  display: block;
  content: '';
  position: absolute;
  background: transparent;
  /* background: rgba(102, 103, 102, 0.5); */
  transition: all 0.25s;
}
.MHub-Menu.outline:not(.horizontal) > .MHub-Menu-Item:not(.header):after {
  width: 0.25rem;
  top: 0;
  bottom: 0;
  right: 0;
}
.MHub-Menu.outline > .MHub-Menu-Item:not(.header):not(.disabled):not(:disabled):hover:after,
.MHub-Menu.outline > .MHub-Menu-Item:not(.header).active:after {
  background: rgba(44, 173, 195, 1);
}
/* .MHub-Menu.outline:not(.horizontal) > .MHub-Menu-Item:not(.header):not(.disabled):not(:disabled):hover:after,
.MHub-Menu.outline:not(.horizontal) > .MHub-Menu-Item:not(.header).active:after {
  width: 0.5rem;
} */

/* outline horizontal */
.MHub-Menu.outline.horizontal > .MHub-Menu-Item {
  margin: 0 0.5rem;
}
.MHub-Menu.outline.horizontal > .MHub-Menu-Item:first-child {
  margin-left: 0;
}
.MHub-Menu.outline.horizontal > .MHub-Menu-Item:last-child {
  margin-right: 0;
}
.MHub-Menu.outline.horizontal > .MHub-Menu-Item:not(.header):after {
  height: 0.25rem;
  bottom: 0;
  right: 0;
  left: 0;
}
/* .MHub-Menu.outline.horizontal > .MHub-Menu-Item:not(.header):not(.disabled):not(:disabled):hover:after,
.MHub-Menu.outline.horizontal > .MHub-Menu-Item:not(.header).active:after {
  height: 0.5rem;
} */

/* tab */
.MHub-Menu.tab > .MHub-Menu-Item {
  color: rgba(163, 164, 163, 1);
  background: rgba(163, 164, 163, 0.5);
  border-radius: 0.285rem 0 0 0.285rem;
  padding: 0.357rem 1rem;
}
.MHub-Menu.tab:not(.horizontal) > .MHub-Menu-Item {
  margin-right: -1px;
  border-right: 1px solid rgba(163, 164, 163, 0.5);
}
.MHub-Menu.tab:not(.horizontal) {
  border-right: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Menu.tab:not(.horizontal) > .MHub-Menu-Item:not(:first-child) {
  margin-top: 0.142rem;
}

/* tab horizontal */
.MHub-Menu.tab.horizontal {
  border-bottom: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Menu.tab.horizontal > .MHub-Menu-Item {
  margin-bottom: -1px;
  border-bottom: 1px solid rgba(163, 164, 163, 0.5);
  border-radius: 0.285rem 0.285rem 0 0;
}
.MHub-Menu.tab.horizontal > .MHub-Menu-Item:not(:first-child) {
  margin-left: 0.142rem;
}

/* inverted */
.MHub-Menu.inverted > .MHub-Menu-Item {
  color: white;
}
.MHub-Menu.tab.inverted {
  border-color: white;
}
.MHub-Menu.tab.inverted > .MHub-Menu-Item {
  background: rgba(255, 255, 255, 0.25);
  border-color: white;
}

/* hover / active */
.MHub-Menu > .MHub-Menu-Item:not(.header):not(.disabled):not(:disabled):hover,
.MHub-Menu > .MHub-Menu-Item:not(.header).active {
  color: rgba(44, 173, 195, 1);
  opacity: 1;
}
.MHub-Menu.inverted > .MHub-Menu-Item:not(.header):not(.disabled):not(:disabled):hover,
.MHub-Menu.inverted > .MHub-Menu-Item:not(.header).active {
  color: white;
  background: rgba(44, 173, 195, 1);
}
.MHub-Menu.outline > .MHub-Menu-Item:not(.header):not(.disabled):not(:disabled):hover,
.MHub-Menu.outline > .MHub-Menu-Item:not(.header).active {
  background: transparent;
  color: rgba(44, 173, 195, 1);
}
.MHub-Menu.tab > .MHub-Menu-Item:not(.header):not(.disabled):not(:disabled):hover,
.MHub-Menu.tab > .MHub-Menu-Item:not(.header).active {
  color: white;
  background: rgba(44, 173, 195, 1);
  border-color: rgba(44, 173, 195, 1);
}
