.MHub-Breadcrumb {
  flex-wrap: wrap;
}

.MHub-Breadcrumb .crumbs {
  /* ellipsis color */
  color: rgba(44, 173, 195, 1);
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
