/* file input */
.MHub-FileInput input[type="file"] {
  display: none;
}

.MHub-FileInput-DropZone {
  display: block;
  box-sizing: border-box;
  transition: all 0.25s;
  overflow: hidden;
  min-width: 5rem;
  min-height: 5rem;
}

.MHub-FileInput-DropZone {
  color: rgba(102, 103, 102, 1);
  border: 1px dotted currentColor;
  border-radius: 0.285rem;
}

/* fix onDragEnter and onDragLeave */
.MHub-FileInput-DropZone > * {
  pointer-events: none;
}

.MHub-FileInput-DropZone.disabled,
.MHub-FileInput-DropZone.active:not(.dropInput) {
  background: rgba(231, 231, 231, 1);
  color: rgba(102, 103, 102, 1);
  cursor: initial;
}

.MHub-FileInput-DropZone:not(.disabled) {
  cursor: pointer;
}

.MHub-FileInput-DropZone:hover:not(.disabled),
.MHub-FileInput-DropZone.active {
  /* NOTE: fix for IE */
  background: rgba(222, 242, 245, 1);
  color: rgba(44, 173, 195, 1);
}
