/* Main container */
.MHub-Steps {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: baseline;
  justify-content: space-between;
}

.MHub-Steps-Item {
  display: inline-flex;
  position: relative;
  transition: all 0.3s;
}

.MHub-Steps .MHub-Steps-Item {
  justify-content: center;
}

.MHub-Steps .MHub-Helper {
  line-height: normal;
  justify-content: center;
}

/* fix wrong calculation of gap length if description too long */
.MHub-Steps .MHub-Helper-Wrapper {
  display: block;
  text-align: center;
}

/* vertical */
.MHub-Steps:not(.horizontal) .MHub-Steps-Item {
  margin: 1rem 0;
}
.MHub-Steps:not(.horizontal) > .MHub-Steps-Item:first-child {
  margin-top: 0;
}
.MHub-Steps:not(.horizontal) > .MHub-Steps-Item:last-child {
  margin-bottom: 0;
}


/* horizontal */
.MHub-Steps.horizontal {
  display: flex;
  flex-direction: row;
  position: relative;
}
.MHub-Steps.horizontal .MHub-Steps-Item {
  margin: 0 1rem;
}
.MHub-Steps.horizontal > .MHub-Steps-Item:first-child {
  margin-left: 0;
}
.MHub-Steps.horizontal > .MHub-Steps-Item:last-child {
  margin-right: 0;
}

/* gap */
.MHub-Steps-Gap {
  position: absolute;
}
.MHub-Steps.horizontal .MHub-Steps-Gap {
  left: 100%;
  top: 50%;
  border-top: 1px solid #A3A4A3;
}
.MHub-Steps:not(.horizontal) .MHub-Steps-Gap {
  top: 100%;
  left: 50%;
  transform: translateX(-100%);
  border-left: 1px solid #A3A4A3;
}

.MHub-Steps-Item.active > .MHub-Steps-Gap {
  border-color: rgba(44, 173, 195, 1);
}
