/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

/* disable blue outline */
.MHub-DatePicker *:focus {
  outline: none;
}

/* fluid calendar */
.MHub-DatePicker.fluid .DayPickerInput-Overlay {
  width: 100%;
  justify-content: center;
  display: flex;
}

.MHub-DatePicker .close-button {
  padding: 0;
}

/* extendable calendar */
.MHub-DatePicker:not(.extendable) .DayPickerInput-Overlay {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  user-select: none;
}

/* nav bar padding */
.MHub-DatePicker-Navbar {
  padding: 1rem !important;
  padding-bottom: 0 !important;
  flex-wrap: nowrap !important;
}

/* clear dropdown styles */
.MHub-DatePicker-Dropdown {
  padding-right: 1rem !important;
  background: none;
  border-bottom: none;
}

.MHub-DatePicker-Dropdown > .MHub-Dropdown-Placeholder {
  padding: 0;
  min-width: 0;
}

/* hide default caption */
.DayPicker-Caption {
  display: none;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  pointer-events: none;
}

/* Example modifiers */

/* highlighted */
.DayPicker-Day--highlighted:not(.DayPicker-Day--outside) {
  background-color: rgba(222, 242, 245, 1);
}

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: rgba(222, 242, 245, 1);
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: rgba(222, 242, 245, 1);
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: rgba(222, 242, 245, 1);
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  left: 0;
  z-index: 5;

  background: rgba(245, 245, 245, 1);
}
