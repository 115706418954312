.MHub-Progress {
  width: 10rem;
  height: 1rem;
}

/* color */
.MHub-Progress.grey {
  background-color: #A3A4A3;
}

.MHub-Progress.blue {
  background-color: rgba(44, 138, 209, 1);
}

.MHub-Progress.red {
  background-color: red;
}

.MHub-Progress.yellow {
  background-color: yellow;
}

.MHub-Progress.orange {
  background-color: #FDBC16;
}

.MHub-Progress.green {
  background-color: green;
}

.MHub-Progress > .progress-value.grey {
  background-color: #666766;
}

.MHub-Progress > .progress-value.blue {
  background-color: rgb(45, 88, 207);
}

.MHub-Progress > .progress-value.red {
  background-color: darkred;
}

.MHub-Progress > .progress-value.yellow {
  background-color: darkgoldenrod;
}

.MHub-Progress > .progress-value.orange {
  background-color: rgb(230, 149, 29);
}

.MHub-Progress > .progress-value.green {
  background-color: rgb(7, 100, 31);
}

/* thin */

.MHub-Progress.thin {
  height: 0.5rem;
}

.MHub-Progress.thin span {
  display: inline-block;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
}

.MHub-Progress.thin span:first-of-type {
  padding-left: 0;
}

.MHub-Progress.fluid {
  flex: 1;
}

.MHub-Progress.disabled {
  opacity: 0.5;
}

.MHub-Progress .progress-value {
  position: relative;
  height: 100%;
  transition: all .4s;
  overflow: hidden;
}

.MHub-Progress .progress-value span {
  display: table-cell;
  padding-left: 0.5rem;
  font-size: 0.8rem;
  color: white;
}

.MHub-Progress.animate .progress-value > .animation {
  height: 100%;
  width: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: -100%;
  margin: 0;
  padding: 0;
  background: currentColor;
  animation: animate 2s ease infinite;
}

@keyframes animate {
  100% {
    left: 0;
    opacity: 0;
  }
}
