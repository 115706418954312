/* main container */
.MHub-Loader {
  z-index: 1000;
  transition: all 0.25s;
}

.MHub-Loader:not(.inline) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
}

/* content */
.MHub-Loader > .content {
  text-align: center;
}

.MHub-Loader > .content > .MHub-Logo {
  width: 4em;
  height: 2em;
}

/* color */
.MHub-Loader.white {
  color: white;
}
.MHub-Loader.grey {
  color: rgba(163, 164, 163, 1);
}
