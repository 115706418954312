.MHub-Scrollbar-Thumb {
  width: 0.5rem;
  transition: background 0.3s ease;
  background: rgba(44, 138, 209, 0.8);
}

.MHub-Scrollbar-Thumb:hover,
.MHub-Scrollbar-Thumb.active {
  background: rgba(44, 138, 209, 1);
}

.MHub-Scrollbar-Track {
  position: absolute;
  width: 0.5rem;
  right: 0;
  bottom: 0;
  top: 0;
  transition: background 0.3s ease;
  background: rgba(163, 164, 163, 0.25);
}

.MHub-Scrollbar-Track:hover,
.MHub-Scrollbar-Track.active {
  background: rgba(163, 164, 163, 0.5);
}
