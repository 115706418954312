/* main container */
.MHub-SnackBar-List {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  user-select: none;
  pointer-events: none;
  transition: all 0.3s;
  z-index: 1000;
}

/* AppNav relations */
body:not(.MHub-AppNav-Visible) .MHub-SnackBar-List {
  margin-left: 5.25rem;
}
body.MHub-AppNav-Visible .MHub-SnackBar-List {
  margin-left: 22rem;
}

/* TODO: to remove all sidebar implementations */
/* main container when sidebar is visible */
body.MHub-SideBar-Visible .MHub-SnackBar-List {
  margin-left: 15rem;
}

/* flash component */
@keyframes flashIntro {
  from {
    opacity: 0;
    width: 0;
    max-height: 0;
    margin: 0 auto;
    padding: 0;
  }
  to {
    opacity: 1;
    width: calc(100% - 1.8rem);
    max-height: initial;
    margin: 0.5rem auto;
    padding: 0.4rem 0.9rem;
  }
}

.MHub-SnackBar {
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 0;
  max-height: 0;
  color: white;
  background: grey;
  border-radius: 1rem;
  user-select: text;
  pointer-events: auto;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  animation: flashIntro 0.3s ease-out reverse;
  transition: all 0.3s;
}

/* visible */
.MHub-SnackBar.visible {
  width: calc(100% - 1.8rem);
  max-height: none;
  margin: 0.5rem auto;
  padding: 0.4rem 0.9rem;
  opacity: 1;
  white-space: normal;
  animation: flashIntro 0.4s cubic-bezier(0.17,0.89,0.37,1.15);
}

/* content */
.MHub-SnackBar > .content {
  flex: 1;
}

/* actions */
.MHub-SnackBar > .actions  {
  flex: none;
}

/* close button */
.MHub-SnackBar > .actions > .close {
  padding: 0.1em;
}

/* color */
.MHub-SnackBar.success {
  background: #9CCE79;
}
.MHub-SnackBar.error {
  background: #DD8787;
}
.MHub-SnackBar.warning {
  background: rgba(253, 190, 159, 1);
}
