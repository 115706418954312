.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  background-color: #000;
}

.ReactCrop:focus {
  outline: none;
}

.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}

.ReactCrop__image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  /* behave similar to Image */
  object-fit: contain;
}

.ReactCrop--crop-invisible .ReactCrop__image {
  opacity: 0.5;
}

.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop__drag-handle {
  position: absolute;
  box-sizing: border-box;
  stroke-linecap: butt;
  outline: 1px solid transparent;
}

/* top-left */
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -2px;
  margin-left: -2px;
  cursor: nw-resize;
}

.ReactCrop__drag-handle.ord-nw {
  border-top: 5px solid rgba(255, 255, 255, 1);
  border-left: 5px solid rgba(255, 255, 255, 1);
  height: 1.75rem;
  width: 1.75rem;
}

/* top */
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -2px;
  cursor: n-resize;
}

.ReactCrop__drag-handle.ord-n {
  border-top: 5px solid rgba(255, 255, 255, 1);
  transform: translateX(-50%);
  width: 1.75rem;
}

/* top-right */
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -2px;
  margin-right: -2px;
  cursor: ne-resize;
}

.ReactCrop__drag-handle.ord-ne {
  border-top: 5px solid rgba(255, 255, 255, 1);
  border-right: 5px solid rgba(255, 255, 255, 1);
  height: 1.75rem;
  width: 1.75rem;
}

/* right */
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-right: -2px;
  cursor: e-resize;
}

.ReactCrop__drag-handle.ord-e {
  transform: translateY(-50%);
  border-right: 5px solid rgba(255, 255, 255, 1);
  height: 1.75rem;
  width: 1.75rem;
}

/* bottom-right */
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -2px;
  margin-right: -2px;
  cursor: se-resize;
}

.ReactCrop__drag-handle.ord-se {
  border-bottom: 5px solid rgba(255, 255, 255, 1);
  border-right: 5px solid rgba(255, 255, 255, 1);
  height: 1.75rem;
  width: 1.75rem;
}

/* bottom */
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -2px;
  cursor: s-resize;
}

.ReactCrop__drag-handle.ord-s {
  transform: translateX(-50%);
  border-bottom: 5px solid rgba(255, 255, 255, 1);
  height: 1.75rem;
  width: 1.75rem;
}

/* bottom-left */
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -2px;
  margin-left: -2px;
  cursor: sw-resize;
}

.ReactCrop__drag-handle.ord-sw {
  border-bottom: 5px solid rgba(255, 255, 255, 1);
  border-left: 5px solid rgba(255, 255, 255, 1);
  height: 1.75rem;
  width: 1.75rem;
}

/* left */
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-left: -2px;
  cursor: w-resize;
}

.ReactCrop__drag-handle.ord-w {
  transform: translateY(-50%);
  border-left: 5px solid rgba(255, 255, 255, 1);
  height: 1.75rem;
  width: 1.75rem;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}

.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}

.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}

.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}

.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}

.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (max-width: 768px), (pointer: coarse) {
  .ReactCrop__drag-handle {
    width: 17px;
    height: 17px;
  }
  .ReactCrop .ord-nw {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-n {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-ne {
    margin-top: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-e {
    margin-top: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-se {
    margin-bottom: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-s {
    margin-bottom: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-sw {
    margin-bottom: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-w {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop__drag-bar.ord-n {
    height: 14px;
    margin-top: -7px;
  }
  .ReactCrop__drag-bar.ord-e {
    width: 14px;
    margin-right: -7px;
  }
  .ReactCrop__drag-bar.ord-s {
    height: 14px;
    margin-bottom: -7px;
  }
  .ReactCrop__drag-bar.ord-w {
    width: 14px;
    margin-left: -7px;
  }
}
