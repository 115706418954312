/* Main container */
.MHub-Button {
  /* reset button */
  display: inline;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  text-decoration: none;
  text-transform: inherit;
  font-family: inherit;
  font-size: 1em;
  font-weight: normal;
  text-align: inherit;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
  /* new */
  position: relative;
  margin: 0;
  padding: 0.357em 0.857em;
  border: 1px solid currentColor;
  border-radius: 1rem;
  box-sizing: border-box;
  background:transparent;
  color: grey;
  transition: all 0.3s ease;
}

/* disable blue background mobile fix */
.MHub-Button:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.MHub-Button:active {
  -webkit-tap-highlight-color: transparent;
}

.MHub-Button.icon > [class^="MHub-Icon"]:not(:only-child) {
  margin-right: 0.5rem;
}

.MHub-Button.basic {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: none !important;
}

.MHub-Button.basic:not(.icon) {
  margin: 0 !important;
  padding: 0 !important;
}

.MHub-Button:not(.outline):not(.borderless):not(.basic) {
  border-color: grey;
  background:grey;
  color: white;
}

.MHub-Button:not(.disabled):not(:disabled) {
  cursor: pointer;
}

/* outline */
.MHub-Button.outline {
  background: transparent;
  color: grey;
}

/* borderless */
.MHub-Button.borderless {
  border-color: transparent;
  background: transparent;
  color: grey;
  box-shadow: none;
}

/* hover */
.MHub-Button:not(.basic):hover:not(.disabled):not(:disabled),
.MHub-Button:not(.basic):active:not(.disabled):not(:disabled) {
  box-shadow: 0.214rem 0.214rem 0.357rem 0 rgba(0, 0, 0, 0.1);
}
.MHub-Button:not(.outline):not(.borderless):hover:not(.disabled):not(:disabled),
.MHub-Button:not(.outline):not(.borderless):active:not(.disabled):not(:disabled) {
  border-color: rgba(102, 102, 102, 1);
  background: rgba(102, 102, 102, 1);
}
.MHub-Button.basic:hover:not(.disabled):not(:disabled),
.MHub-Button.basic:active:not(.disabled):not(:disabled),
.MHub-Button.outline:hover:not(.disabled):not(:disabled),
.MHub-Button.outline:active:not(.disabled):not(:disabled),
.MHub-Button.borderless:hover:not(.disabled):not(:disabled),
.MHub-Button.borderless:active:not(.disabled):not(:disabled) {
  color: rgba(102, 102, 102, 1);
}
.MHub-Button:active:not(.disabled):not(:disabled) {
  background: rgba(240, 240, 240, 1);
}

/* ripple */
@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
/* make the text in front of the ripple */
.MHub-Button > .ripple-children {
  position: relative;
  z-index: 1;
}

/* fix IE button animation */
.MHub-Button > span,
.MHub-Button > .loading {
  position: relative;
}

.MHub-Button > .ripple-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 1rem;
  overflow: hidden;
  /* safari fix overflow hidden */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

/* normal ripple style */
.MHub-Button.ripple > .ripple-container span {
  position: absolute;
  transform: scale(0);
  border-radius: 100%;
  background-color: grey;
  animation: ripple 500ms;
}

/* outline and borderless ripple style */
.MHub-Button.outline.ripple > .ripple-container span,
.MHub-Button.borderless.ripple > .ripple-container span {
  background-color: currentColor;
}

/* ===== color ==== */
/* blue */
.MHub-Button.blue {
  color: rgba(44, 173, 195, 1);
}
.MHub-Button.blue:not(.outline):not(.borderless):not(.basic) {
  border-color: rgba(44, 173, 195, 1);
  background: rgba(44, 173, 195, 1);
  color: white;
}
.MHub-Button.blue:not(.outline):not(.borderless):hover:not(.disabled):not(:disabled),
.MHub-Button.blue:not(.outline):not(.borderless):active:not(.disabled):not(:disabled) {
  border-color: rgba(60, 198, 221, 1);
  background: rgba(60, 198, 221, 1);
}
.MHub-Button.blue.basic:hover:not(.disabled):not(:disabled),
.MHub-Button.blue.basic:active:not(.disabled):not(:disabled),
.MHub-Button.blue.outline:hover:not(.disabled):not(:disabled),
.MHub-Button.blue.outline:active:not(.disabled):not(:disabled),
.MHub-Button.blue.borderless:hover:not(.disabled):not(:disabled),
.MHub-Button.blue.borderless:active:not(.disabled):not(:disabled) {
  color: rgba(60, 198, 221, 1);
}
.MHub-Button.blue.ripple > .ripple-container span {
  background: rgba(44, 173, 195, 1);
}
.MHub-Button.blue:active:not(.disabled):not(:disabled),
.MHub-Button.blue.outline.ripple > .ripple-container span,
.MHub-Button.blue.borderless.ripple > .ripple-container span {
  background: rgba(222, 242, 245, 1);
}

/* red */
.MHub-Button.red {
  color: red;
}
.MHub-Button.red:not(.outline):not(.borderless):not(.basic) {
  border-color: red;
  background: red;
  color: white;
}
.MHub-Button.red:not(.outline):not(.borderless):hover:not(.disabled):not(:disabled),
.MHub-Button.red:not(.outline):not(.borderless):active:not(.disabled):not(:disabled) {
  border-color: darkred;
  background: darkred;
}
.MHub-Button.red.basic:hover:not(.disabled):not(:disabled),
.MHub-Button.red.basic:active:not(.disabled):not(:disabled),
.MHub-Button.red.outline:hover:not(.disabled):not(:disabled),
.MHub-Button.red.outline:active:not(.disabled):not(:disabled),
.MHub-Button.red.borderless:hover:not(.disabled):not(:disabled),
.MHub-Button.red.borderless:active:not(.disabled):not(:disabled) {
  color: darkred;
}
.MHub-Button.red.ripple > .ripple-container span {
  background: red;
}
.MHub-Button.red:active:not(.disabled):not(:disabled),
.MHub-Button.red.outline.ripple > .ripple-container span,
.MHub-Button.red.borderless.ripple > .ripple-container span {
  background: pink;
}

/* white */
.MHub-Button.white {
  color: white;
}
.MHub-Button.white:not(.outline):not(.borderless):not(.basic) {
  border-color: white;
  background: white;
  color: black;
}
.MHub-Button.white:not(.outline):not(.borderless):hover:not(.disabled):not(:disabled),
.MHub-Button.white:not(.outline):not(.borderless):active:not(.disabled):not(:disabled) {
  border-color: rgba(250, 250, 250, 1);
  background: rgba(250, 250, 250, 1);
}
.MHub-Button.white.basic:hover:not(.disabled):not(:disabled),
.MHub-Button.white.basic:active:not(.disabled):not(:disabled),
.MHub-Button.white.outline:hover:not(.disabled):not(:disabled),
.MHub-Button.white.outline:active:not(.disabled):not(:disabled),
.MHub-Button.white.borderless:hover:not(.disabled):not(:disabled),
.MHub-Button.white.borderless:active:not(.disabled):not(:disabled) {
  color: rgba(250, 250, 250, 1);
}
.MHub-Button.white.ripple > .ripple-container span {
  background: rgba(225, 225, 225, 1);
}
.MHub-Button.white:active:not(.disabled):not(:disabled),
.MHub-Button.white.outline.ripple > .ripple-container span,
.MHub-Button.white.borderless.ripple > .ripple-container span {
  background: rgba(225, 225, 225, 1);
}

/* green */
.MHub-Button.green {
  color: green;
}
.MHub-Button.green:not(.outline):not(.borderless):not(.basic) {
  border-color: green;
  background: green;
  color: white;
}
.MHub-Button.green:not(.outline):not(.borderless):hover:not(.disabled):not(:disabled),
.MHub-Button.green:not(.outline):not(.borderless):active:not(.disabled):not(:disabled) {
  border-color: darkgreen;
  background: darkgreen;
}
.MHub-Button.green.basic:hover:not(.disabled):not(:disabled),
.MHub-Button.green.basic:active:not(.disabled):not(:disabled),
.MHub-Button.green.outline:hover:not(.disabled):not(:disabled),
.MHub-Button.green.outline:active:not(.disabled):not(:disabled),
.MHub-Button.green.borderless:hover:not(.disabled):not(:disabled),
.MHub-Button.green.borderless:active:not(.disabled):not(:disabled) {
  color: darkgreen;
}
.MHub-Button.green.ripple > .ripple-container span {
  background: green;
}
.MHub-Button.green:active:not(.disabled):not(:disabled),
.MHub-Button.green.outline.ripple > .ripple-container span,
.MHub-Button.green.borderless.ripple > .ripple-container span {
  background: lightgreen;
}

/* disabled */
.MHub-Button:not(.outline):not(.borderless):not(.basic):disabled,
.MHub-Button:not(.outline):not(.borderless):not(.basic).disabled {
  border-color: rgba(231, 231, 231, 1);
  background: rgba(231, 231, 231, 1);
  color: rgba(197, 197, 197, 1);
}
.MHub-Button.basic:disabled,
.MHub-Button.basic.disabled,
.MHub-Button.outline:disabled,
.MHub-Button.outline.disabled,
.MHub-Button.borderless:disabled,
.MHub-Button.borderless.disabled {
  color: rgba(197, 197, 197, 1);
}


