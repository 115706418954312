@keyframes brick1_blink {
  12.5% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  37.5% {
    opacity: 1;
  }
}
@keyframes brick2_blink {
  18.75% {
    opacity: 1;
  }
  31.25% {
    opacity: 0.5;
  }
  43.75% {
    opacity: 1;
  }
}
@keyframes brick3_blink {
  25% {
    opacity: 1;
  }
  37.5% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}
@keyframes brick4_blink {
  31.25% {
    opacity: 1;
  }
  43.75% {
    opacity: 0.5;
  }
  56.25% {
    opacity: 1;
  }
}
@keyframes brick5_blink {
  37.5% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  62.5% {
    opacity: 1;
  }
}
@keyframes brick6_blink {
  43.75% {
    opacity: 1;
  }
  56.25% {
    opacity: 0.5;
  }
  68.75% {
    opacity: 1;
  }
}
@keyframes brick7_blink {
  50% {
    opacity: 1;
  }
  62.5% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
  }
}
@keyframes brick8_blink {
  56.25% {
    opacity: 1;
  }
  68.75% {
    opacity: 0.5;
  }
  82.25% {
    opacity: 1;
  }
}

/* main container */
.MHub-Logo {
  display: inline-block;
}
.MHub-Logo > svg {
  width: 100%;
  height: 100%;
}
.MHub-Logo > svg > g > polygon {
  opacity: 1;
  transition: all 0.3s;
}
.MHub-Logo > svg > g > .brick-1,
.MHub-Logo > svg > g > .brick-2 {
  fill: #00A5BF;
}
.MHub-Logo > svg > g > .brick-3,
.MHub-Logo > svg > g > .brick-4 {
  fill: #96D0A6;
}
.MHub-Logo > svg > g > .brick-5,
.MHub-Logo > svg > g > .brick-6 {
  fill: #2F964A;
}
.MHub-Logo > svg > g > .brick-7,
.MHub-Logo > svg > g > .brick-8 {
  fill: #09B095;
}

/* loading */
.MHub-Logo.loading > svg > g > .brick-1 {
  animation: brick1_blink 1.75s ease-in infinite;
}
.MHub-Logo.loading > svg > g > .brick-2 {
  animation: brick2_blink 1.75s ease-in infinite;
}
.MHub-Logo.loading > svg > g > .brick-3 {
  animation: brick3_blink 1.75s ease-in infinite;
}
.MHub-Logo.loading > svg > g > .brick-4 {
  animation: brick6_blink 1.75s ease-in infinite;
}
.MHub-Logo.loading > svg > g > .brick-5 {
  animation: brick7_blink 1.75s ease-in infinite;
}
.MHub-Logo.loading > svg > g > .brick-6 {
  animation: brick8_blink 1.75s ease-in infinite;
}
.MHub-Logo.loading > svg > g > .brick-7 {
  animation: brick5_blink 1.75s ease-in infinite;
}
.MHub-Logo.loading > svg > g > .brick-8 {
  animation: brick4_blink 1.75s ease-in infinite;
}

/* color */
.MHub-Logo.grey > svg > g > polygon {
  fill: rgba(163, 164, 163, 1);
}
.MHub-Logo.white > svg > g > polygon {
  fill: white;
}
.MHub-Logo.blue > svg > g > polygon {
  fill: rgba(44, 173, 195, 1);
}
.MHub-Logo.red > svg > g > polygon {
  fill: red;
}
.MHub-Logo.green > svg > g > polygon {
  fill: green;
}
