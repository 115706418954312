.MHub-Slider {
  display: flex;
}

.MHub-Slider .MHub-Slider-Wrapper {
  display: flex;
  position: relative;
  align-items: center;
  width: 15rem;
  padding: 1rem 0;
  margin: 0;
}

.MHub-Slider.vertical .MHub-Slider-Wrapper {
  width: 1rem;
  height: 15rem;
  padding: 0;
}

.MHub-Slider .MHub-Slider-Wrapper.fluid {
  width: 100%;
  flex: 1;
}

.MHub-Slider.vertical .MHub-Slider-Wrapper.fluid {
  /* parent must have fixed height in order to vertical fluid */
  height: 100%;
  flex: 1;
}

.MHub-Slider .MHub-Slider-Track {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 0.4rem;
  background: rgba(163, 164, 163, 1);
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 0;
}

.MHub-Slider.vertical .MHub-Slider-Track {
  height: 100%;
  width: 0.4rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.MHub-Slider .MHub-Slider-Track:focus {
  outline: none;
}

.MHub-Slider .MHub-Slider-Highlight {
  position: absolute;
  cursor: pointer;
  /* width and left defined outside */
  height: 0.4rem;
  background: rgba(44, 173, 195, 1);
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 1;
}

.MHub-Slider.vertical .MHub-Slider-Highlight {
  width: 0.4rem;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.MHub-Slider .MHub-Slider-Highlight:focus {
  outline: none;
}

.MHub-Slider .MHub-Slider-Knob {
  position: absolute;
  /* fix IE */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(112, 112, 112, 1);
  z-index: 2;
}

.MHub-Slider.vertical .MHub-Slider-Knob {
  /* fix IE */
  left: 50%;
  transform: translateX(-50%);
}

.MHub-Slider .MHub-Slider-Knob:focus {
  outline: none;
}
