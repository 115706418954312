

/* base input */
.MHub-Input-Base {
  flex: 1;
  min-width: 5rem;
  width: 100%;
  margin: 0;
  padding: 0;
}

.MHub-Input-Base + .suffix > .show-password-icon {
  padding: 0;
}

.MHub-Input-Base:read-only {
  cursor: inherit;
}

input.MHub-Input-Base::placeholder {
  color: #C8C8C8;
  user-select: none;
}

.MHub-Input.inverted > input.MHub-Input-Base::placeholder {
  color: white;
}

.MHub-Input.disabled > input.MHub-Input-Base::placeholder {
  color: rgba(197, 197, 197, 0.5);
}
