/* Main container */
.MHub-Header  {
  display: inline-block;
  white-space: pre-wrap;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font-weight: normal;
  transition: all 0.25s;
}

/* bold */
.MHub-Header.bold {
  font-weight: bold;
}

/* size */
h1.MHub-Header {
  font-size: 2.2em;
}
h2.MHub-Header {
  font-size: 2em;
}
h3.MHub-Header {
  font-size: 1.5em;
}
h4.MHub-Header {
  font-size: 1.1em;
}
h5.MHub-Header {
  font-size: 1em;
}
h6.MHub-Header {
  font-size: 0.8em;
}

/* description */
.MHub-Header > .description {
  font-weight: normal;
  color: rgba(163, 164, 163, 1);
  font-size: 1rem;
  text-transform: initial;
}

/* inverted */
.MHub-Header.inverted,
.MHub-Header.inverted > .description {
  color: white;
}
