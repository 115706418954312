@import url(https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin);

/* mhub icon font */
@font-face {
  font-family: 'MHub-Icons';
  src:  url('./fonts/MHub-Icons.eot?67lg5m');
  src:  url('./fonts/MHub-Icons.eot?67lg5m#iefix') format('embedded-opentype'),
    url('./fonts/MHub-Icons.ttf?67lg5m') format('truetype'),
    url('./fonts/MHub-Icons.woff?67lg5m') format('woff'),
    url('./fonts/MHub-Icons.svg?67lg5m#MHub-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* base font size */
html {
  font-size: 14px;
}

/* a tag */
a {
  text-decoration: none;
}
a,
a:visited {
  color: rgba(44, 173, 195, 1);
}
a:hover,
a:active {
  color: rgba(60, 198, 221, 1);
}

/* base */
[class^="MHub-"] {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

/* fluid */
[class^="MHub-"].fluid {
  width: 100%;
}

/* attached */
[class^="MHub-"].attached {
  border-radius: 0 !important;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-left: 0;
}
[class^="MHub-"].attached-horizontal {
  border-radius: 0 !important;
  margin-left: 0;
  margin-right: 0;
}
[class^="MHub-"].attached-vertical {
  border-radius: 0 !important;
  margin-top: 0;
  margin-left: 0;
}
[class^="MHub-"].attached-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-right: 0;
}
[class^="MHub-"].attached-right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: 0;
}
[class^="MHub-"].attached-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-bottom: 0;
}
[class^="MHub-"].attached-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-top: 0;
}

/* align */

/* text align */
[class^="MHub-"].text-align-left {
  text-align: left;
}
[class^="MHub-"].text-align-center {
  text-align: center;
}
[class^="MHub-"].text-align-right {
  text-align: right;
}
[class^="MHub-"].text-align-justify {
  text-align: justify;
}

/* vertical align */


/* float */
[class^="MHub-"].float-left {
  float: left;
}
[class^="MHub-"].float-right {
  float: right;
}

/* clickable */
[class^="MHub-"].clickable:not(.disabled):not(:disabled):not(.readOnly) {
  cursor: pointer;
}

/* text transform */
[class^="MHub-"].capitalize {
  text-transform: capitalize;
}

[class^="MHub-"].upperCase {
  text-transform: uppercase;
}

[class^="MHub-"].lowerCase {
  text-transform: lowercase;
}

/* clearing */
.clearing {
  clear: both;
}

/* scrollbar */
* {
  /* IE */
  scrollbar-face-color: rgba(44, 138, 209, 1);
  scrollbar-shadow-color: rgba(44, 138, 209, 1);
  scrollbar-darkshadow-color: rgba(44, 138, 209, 1);
  scrollbar-track-color: rgba(163, 164, 163, 0.25);
  scrollbar-arrow-color: rgba(44, 138, 209, 1);
  /* FireFox */
  scrollbar-color: rgba(44, 138, 209, 1) rgba(163, 164, 163, 0.25);
  scrollbar-width: thin;
}

/* Chrome */
*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
*::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
*::-webkit-scrollbar-thumb {
  background: rgba(44, 138, 209, 1);
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(44, 138, 209, 1);
}
*::-webkit-scrollbar-thumb:active {
  background: rgba(44, 138, 209, 1);
}
*::-webkit-scrollbar-track {
  background: rgba(163, 164, 163, 0.25);
}
*::-webkit-scrollbar-track:hover {
  background: rgba(163, 164, 163, 0.5);
}
*::-webkit-scrollbar-track:active {
  background: rgba(163, 164, 163, 0.5);
}
