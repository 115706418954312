/* Main container */
.MHub-List {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.MHub-List-Item {
  display: list-item;
  margin: 0;
  padding: 0.5rem 0;
  list-style-type: none;
  list-style-position: outside;
  border: none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 1em;
  background: transparent;
  font-family: inherit;
  box-sizing: inherit;
  text-align: inherit;
}

/* horizontal */
.MHub-List.horizontal {
  display: flex;
  flex-direction: row;
}
.MHub-List.horizontal > .MHub-List-Item {
  padding: 0 0.5rem;
}

/* divider between items */
.MHub-List.divided:not(.horizontal) > .MHub-List-Item:not(:last-child):not(.header) {
  border-bottom: 1px solid rgba(163, 164, 163, 0.5);
}
.MHub-List.divided.horizontal > .MHub-List-Item:not(:last-child):not(.header) {
  border-right: 1px solid rgba(163, 164, 163, 0.5);
}


/* header */
.MHub-List > .MHub-List-Item.header {
  display: block;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

/* ordered */
.MHub-List[class*="ordered-"] > .MHub-List-Item:not(.header) {
  margin-left: 1.25rem;
}
.MHub-List.ordered-bullet > .MHub-List-Item:not(.header) {
  list-style-type: disc;
}
.MHub-List.ordered-decimal > .MHub-List-Item:not(.header) {
  list-style-type: decimal;
}
.MHub-List.ordered-lower-alpha > .MHub-List-Item:not(.header) {
  list-style-type: lower-alpha;
}
.MHub-List.ordered-upper-alpha > .MHub-List-Item:not(.header) {
  list-style-type: upper-alpha;
}

/* full */
.MHub-List.full > .MHub-List-Item {
  flex: 1;
}
