.MHub-Image {
  position: relative;
  display: flex;
  min-width: 5rem;
  min-height: 5rem;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: all 0.25s;
}
.MHub-Image:not(.loaded) {
  background: rgba(163, 164, 163, 0.25);
}

/* clickable */
.MHub-Image.clickable {
  cursor: pointer;
}

.MHub-Image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: all 0.25s;
}
.MHub-Image.loaded > img {
  opacity: 1;
}

.MHub-Image > .placeholder {
  color: rgba(163, 164, 163, 1);
}

/* inverted */
.MHub-Image.inverted:not(.loaded) {
  background: rgba(255, 255, 255, 0.5);
}
.MHub-Image.inverted > .placeholder {
  color: white;
}

/* fix for IE */
.MHub-Image.fluid > img {
  width: auto;
  height: auto;
  min-width: 1rem;
  min-height: 1rem;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
