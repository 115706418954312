.MHub-Badge {
  display: inline-flex;
  min-width: 1rem;
  min-height: 1rem;
  border-radius: 9999rem;
  font-size: 0.8em;
  line-height: 0.8em;
  margin: 0;
  padding: 0.285rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(163, 164, 163, 1);
  color: white;
  box-sizing: border-box;
  transition: all 0.3s;
  white-space: nowrap;
}

.MHub-Badge.outline {
  background: none;
  color: rgba(163, 164, 163, 1);
  border: 1px solid currentColor;
}

/* sticky */
.MHub-Badge.direction-top {
  top: 0;
  bottom: auto;
  transform: translate(-50%, -50%) !important;
}
.MHub-Badge.direction-left {
  left: 0;
  right: auto;
  transform: translate(-50%, -50%) !important;
}
.MHub-Badge.direction-right {
  right: 0;
  left: auto;
  transform: translate(50%, -50%) !important;
}
.MHub-Badge.direction-bottom {
  bottom: 0;
  top: auto;
  transform: translate(-50%, 50%) !important;
}
.MHub-Badge[class*="direction-"]:not(.direction-top):not(.direction-bottom) {
  top: 50%;
  bottom: auto;
}

.MHub-Badge.direction-bottom.direction-right {
  transform: translate(50%, 50%) !important;
}

/* circular */
.MHub-Badge.circular {
  min-width: 1.5rem;
  height: 1.5rem;
}

/* colors */
.MHub-Badge:not(.outline).blue {
  background: rgba(44, 173, 195, 1);
}
.MHub-Badge.outline.blue {
  color: rgba(44, 173, 195, 1);
}

.MHub-Badge:not(.outline).red {
  background: red;
}
.MHub-Badge.outline.red {
  color: red;
}

.MHub-Badge:not(.outline).yellow {
  background: yellow;
}
.MHub-Badge.outline.yellow {
  color: yellow;
}

.MHub-Badge:not(.outline).orange {
  background: #FDBC16;
}
.MHub-Badge.outline.orange {
  color: #FDBC16;
}

.MHub-Badge:not(.outline).green {
  background: green;
}
.MHub-Badge.outline.green {
  color: green;
}
