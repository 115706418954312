.MHub-TimePicker .close-button {
  padding: 0;
}

.MHub-TimePicker-Options {
  display: flex;
}

.MHub-TimePicker-Options .columns {
  flex: 1;
  padding: 0 !important;
}

.MHub-TimePicker-Options .options {
  /* IE flexbox fix */
  position: relative;
  top: 0;
  border: none;
}
