/* main container */
.MHub-Table {
  display: table;
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  flex: 1;
  width: 100%;
  background: rgba(245, 245, 245, 1);
  padding: 1rem;
}

.MHub-Table.basic,
.MHub-Table.basic [class^="MHub-Table-"] {
  background: transparent !important;
}

.MHub-Table.basic[class*="sticky"] [class^="MHub-Table-"] {
  background: white !important;
}

/* header */
.MHub-Table > .MHub-Table-Header {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
/* tbody */
.MHub-Table > .MHub-Table-Body {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
/* footer */
.MHub-Table > .MHub-Table.Footer {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
}
/* row */
.MHub-Table-Row {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
/* cell */
.MHub-Table-Cell {
  display: table-cell;
  vertical-align: inherit;
  padding: 0.628rem 1rem;
}
.MHub-Table-Cell.header {
  font-weight: bold;
}
.MHub-Table-Cell.collapse {
  width: 1px;
  white-space: nowrap;
}

/* collapse */
.MHub-Table.collapse {
  border-collapse: collapse;
}

/* sticky */
.MHub-Table.sticky {
  position: relative;
}
.MHub-Table.sticky {
  border-collapse: initial;
}
.MHub-Table.sticky > .MHub-Table-Header[class*="sticky"] .MHub-Table-Cell,
.MHub-Table.sticky > .MHub-Table-Footer[class*="sticky"] .MHub-Table-Cell,
.MHub-Table.sticky > .MHub-Table-Body.sticky-first .MHub-Table-Cell:first-child,
.MHub-Table.sticky > .MHub-Table-Body.sticky-last .MHub-Table-Cell:last-child {
  position: sticky;
  position: -webkit-sticky;
  background: inherit;
  z-index: 1;
}
.MHub-Table > .MHub-Table-Header:not([class*="sticky"]),
.MHub-Table.sticky > .MHub-Table-Header > .MHub-Table-Row:last-child > .MHub-Table-Cell {
  border-bottom: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Table > .MHub-Table-Footer:not([class*="sticky"]),
.MHub-Table.sticky > .MHub-Table-Footer > .MHub-Table-Row:first-child > .MHub-Table-Cell {
  border-top: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Table.sticky > .MHub-Table-Header[class*="sticky"] > .MHub-Table-Row > .MHub-Table-Cell,
.MHub-Table.sticky > .MHub-Table-Footer[class*="sticky"] > .MHub-Table-Row > .MHub-Table-Cell {
  z-index: 2;
  background: rgba(245, 245, 245, 1);
}
.MHub-Table.sticky > .MHub-Table-Header[class*="sticky"] > .MHub-Table-Row > .MHub-Table-Cell {
  top: 0;
}
.MHub-Table.sticky > .MHub-Table-Footer[class*="sticky"] > .MHub-Table-Row > .MHub-Table-Cell {
  bottom: 0;
}
.MHub-Table.sticky > .MHub-Table-Header.sticky-first .MHub-Table-Cell:first-child,
.MHub-Table.sticky > .MHub-Table-Footer.sticky-first .MHub-Table-Cell:first-child,
.MHub-Table.sticky > .MHub-Table-Body.sticky-first .MHub-Table-Cell:first-child {
  left: 0;
  border-right: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Table.sticky > .MHub-Table-Header.sticky-last .MHub-Table-Cell:last-child,
.MHub-Table.sticky > .MHub-Table-Footer.sticky-last .MHub-Table-Cell:last-child,
.MHub-Table.sticky > .MHub-Table-Body.sticky-last .MHub-Table-Cell:last-child {
  right: 0;
  border-left: 1px solid rgba(163, 164, 163, 1);
}
.MHub-Table.sticky > .MHub-Table-Header.sticky-first .MHub-Table-Cell:first-child,
.MHub-Table.sticky > .MHub-Table-Footer.sticky-first .MHub-Table-Cell:first-child,
.MHub-Table.sticky > .MHub-Table-Header.sticky-last .MHub-Table-Cell:last-child,
.MHub-Table.sticky > .MHub-Table-Footer.sticky-last .MHub-Table-Cell:last-child {
  z-index: 3;
}

/* striped background */
.MHub-Table-Row {
  background: rgba(245, 245, 245, 1);
}
.MHub-Table.striped > .MHub-Table-Body > .MHub-Table-Row:nth-child(even) {
  background: rgba(44, 138, 209, 0.1);
}
.MHub-Table.selectable > .MHub-Table-Body > .MHub-Table-Row:hover {
  box-shadow: 0.571rem 0.571rem 0.714rem 0 rgba(0, 0, 0, 0.1);
}
.MHub-Table-Row.selected {
  background: rgba(44, 138, 209, 0.5);
}

/* bordered */
.MHub-Table.bordered {
  border: 1px solid #ccc;
}
.MHub-Table.bordered > .MHub-Table-Body > .MHub-Table-Row:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.MHub-Table.bordered > .MHub-Table-Body > .MHub-Table-Cell:not(:first-child) {
  border-left: 1px solid #ccc;
}
