.MHub-ColorPicker .input-wrapper {
  display: flex;
  flex: 1;
}

.MHub-ColorPicker .dropdown-wrapper {
  z-index: 2;
}

.MHub-ColorPicker:not(.extendable) .dropdown-wrapper {
  position: absolute;
}

.MHub-ColorPicker .preview {
  width: 3.5rem;
  border-radius: 0.285rem;
  cursor: pointer;
}

.MHub-ColorPicker .MHub-ColorPicker-Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
