.MHub-Pagination {
  display: flex;
  /* IE flexbox fix */
  flex-wrap: nowrap;
  flex-basis: auto;
  font-size: 0.8em;
}

.MHub-Pagination .MHub-Column {
  /* IE flexbox fix */
  flex-basis: auto;
  padding: 0;
}

.MHub-Pagination .input-main-wrapper {
  display: flex;
}

/* IE flexbox fix */
.MHub-Pagination.IE .input-main-wrapper {
  flex: 1;
}

.MHub-Pagination .MHub-Label {
  /* overrides label flex css */
  flex: 1 0 auto;
}

.MHub-Pagination.fluid {
  display: flex;
  justify-content: space-between;
}

.MHub-Pagination .back-button {
  padding-left: 0;
  padding-right: 0;
}

.MHub-Pagination .ellipsis {
  cursor: default;
}

.MHub-Pagination .next-button {
  padding-left: 0;
  padding-right: 0;
}

.MHub-Pagination .list-wrapper {
  align-items: baseline;
}

.MHub-Pagination .input-wrapper {
  display: flex;
  margin-left: 0.85rem;
}

.MHub-Pagination .input-wrapper input {
  min-width: 1rem;
}
