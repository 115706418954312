.MHub-GraphQLHandler {
  position: relative;
}

/* loading */
.MHub-GraphQLHandler.loading {
  min-height: 5rem;
}

/* without overlay */
.MHub-GraphQLHandler:not(.withOverlay) {
  background: transparent;
  transition: all 0.25s ease-out;
}

.MHub-GraphQLHandler.loading.dimOverlayOnLoading:not(.withOverlay) {
  background: rgba(0, 0, 0, 0.1);
}

/* with overlay */
.MHub-GraphQLHandler.loading.dimOverlayOnLoading .MHub-GraphQLHandler-Overlay {
  background: rgba(0, 0, 0, 0.1);
}

.MHub-GraphQLHandler-Overlay {
  top: 0;
  z-index: 50;
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: all 0.25s ease-out;
  pointer-events: none;
}
