.MHub-PreSuffix > .prefix,
.MHub-PreSuffix > .suffix {
  color: rgba(51, 51, 51, 1);
}

/* prefix */
.MHub-PreSuffix > .prefix {
  padding-right: 0.25em;
}

/* suffix */
.MHub-PreSuffix > .suffix {
  padding-left: 0.25em;
}
