[class^="MHub-Icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'MHub-Icons' !important;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="MHub-Icon"].blue {
  color: rgba(44, 173, 195, 1);
}

[class^="MHub-Icon"].grey {
  color: rgba(163, 164, 163, 1);
}

[class^="MHub-Icon"].red {
  color: red;
}

[class^="MHub-Icon"].white {
  color: white;
}
